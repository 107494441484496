/* ⛔️ Global styles should be added here rarely - prefer scoped styles on components */

@font-face {
  font-family: "Aeonik Pro";
  src: url("../fonts/AeonikPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik Pro";
  src: url("../fonts/AeonikPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik Pro";
  src: url("../fonts/AeonikPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik Pro";
  src: url("../fonts/AeonikPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* Import modern-normalize so it gets bundled and minified into our CSS */
@import url("modern-normalize.css");

html,
body {
  /* Add anti-aliasing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
